import {
    SIGNUP_PHONE_WE_SENT_YOU_NEW_ACTIVATION_CODE_SMS,
    YOU_WERE_SUCCESSFULLY_LOGGED_IN_AS_username,
} from 'mk/autogenerated/translations/Login.sagas.4379fcb99fcaa71339ea0c0ea13ffbfa'
import { canManageBlogs, canManageForum, canManageGroups } from 'mk/bazaar/common/userUtils';
import {
    users_api_auth_login_load_url,
    users_api_auth_login_url,
} from 'mk/urls/functions';
import { login as loginActionCreator } from 'mk2/actions';
import { homepageUrl } from 'mk2/apps/home/urls';
import {
    loginAPI,
    loginFetchApi,
    LoginFetchNormalizedResponse,
    LoginFetchTriggerAction,
    LoginNormalizedResponse,
    LoginTriggerAction,
    LOGIN_FETCH_TRIGGER,
    LOGIN_TRIGGER,
} from 'mk2/apps/users/containers/Login/Login.actions';
import { LOGIN_FORM_NAME } from 'mk2/apps/users/containers/Login/LoginForm';
import {
    gotoUrlAfterLoginOrSignup, gtmEvent,
    isNextPagePWA,
    storeLastKnownUsernameCookie,
} from 'mk2/apps/users/utils';
import { FormPageType } from 'mk2/constants/enums';
import { pageHeaderFetchFlaggedCountsTrigger, pageHeaderLoad } from 'mk2/containers/PageHeader/PageHeader.action';
import { handleXHRGetErrorSaga, normalizeError, XHRAction, XHRFormError, XHRGetError } from 'mk2/helpers/api';
import { handleXHRFormErrorSaga, handleXHRFormSuccessSaga } from 'mk2/helpers/form';
import { mkReduxFormOpenPage } from 'mk2/helpers/form.actions';
import { showSuccessToast } from 'mk2/helpers/toasts';
import { getLogger } from 'mk2/logger';
import { getRequestBaseUrl, getRequestPermissions, AppStateProvidedFromServer } from 'mk2/reducers';
import { UserEntity, UserSchema } from 'mk2/schemas';
import { getRoutingLocation } from 'mk2/selectors';
import { interpolate } from 'mk2/services/i18n';
import { normalize } from 'normalizr';
import { change, stopSubmit } from 'redux-form';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

const logger = getLogger('users/Login.sagas');

interface ApiResponseLoginFetch {
    body: LoginFetchNormalizedResponse;
}

function* fetch({contentItemsKey, xhr}: LoginFetchTriggerAction & XHRAction) {
    yield put(loginFetchApi.request(contentItemsKey));

    const location = yield select(getRoutingLocation);
    try {
        const response: ApiResponseLoginFetch = yield call(
            () => xhr.get(users_api_auth_login_load_url(), {
                contentItemsKey,
            }),
        );
        yield put(loginFetchApi.success(contentItemsKey, response.body));
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(loginFetchApi.failure(contentItemsKey, normalizeError(error)));
    }
}

interface ApiResponseLogin {
    body: {
        user: UserEntity;
        initialState: AppStateProvidedFromServer;
        lastLoginVerified: boolean;
        phoneNumber: string;
    };
}


function* login({loginFd, action, resend, xhr}: LoginTriggerAction & XHRAction) {
    yield put(loginAPI.request(loginFd));

    try {
        const response: ApiResponseLogin = yield call(
            () => xhr.post(users_api_auth_login_url(), {
                'action': action,
                loginFd,
            }),
        );

        const normalizedResponse: LoginNormalizedResponse = normalize(response.body, {user: UserSchema});
        const page = normalizedResponse.result.phoneNumber === '' ? FormPageType.LoginSupport : FormPageType.LoginPhoneVerification;

        // ensure we don't put null in lastLoginVerified and phoneNumber
        yield put(change(LOGIN_FORM_NAME, `lastLoginVerified`, normalizedResponse.result.lastLoginVerified ? normalizedResponse.result.lastLoginVerified : true));
        yield put(change(LOGIN_FORM_NAME, `phoneNumber`, normalizedResponse.result.phoneNumber ? normalizedResponse.result.phoneNumber : ''));

        if (response.body.lastLoginVerified) {

            // Login in redux before any URL redirect
            yield put(loginActionCreator(response.body.initialState));

            storeLastKnownUsernameCookie(response.body.user.username);

            const msg = interpolate(YOU_WERE_SUCCESSFULLY_LOGGED_IN_AS_username, {username: response.body.user.username});
            const baseUrl = yield select(getRequestBaseUrl);
            const location = yield select(getRoutingLocation);
            const canRedirectInPWA = isNextPagePWA(location, homepageUrl);
            const gotoUrl = gotoUrlAfterLoginOrSignup(baseUrl, location, homepageUrl, canRedirectInPWA);

            xhr.refreshApiAuth();
            if (canRedirectInPWA) {
                yield put(pageHeaderLoad());
                const requestUserPermissions: string[] = yield select(getRequestPermissions);
                const isBlogsAdmin = canManageBlogs(requestUserPermissions);
                const isForumAdmin = canManageForum(requestUserPermissions);
                const isGroupAdmin = canManageGroups(requestUserPermissions);
                if (isBlogsAdmin || isForumAdmin || isGroupAdmin) {
                    yield put(pageHeaderFetchFlaggedCountsTrigger());
                }
            }

            yield handleXHRFormSuccessSaga(LOGIN_FORM_NAME, gotoUrl, msg);
            yield put(loginAPI.success(
                loginFd,
                response.body.user,
                response.body.initialState,
                normalizedResponse,
            ));
            gtmEvent('event_login');

        } else {
            yield put(mkReduxFormOpenPage(LOGIN_FORM_NAME, page));
            yield put(stopSubmit(LOGIN_FORM_NAME));
            if (resend) {
                yield showSuccessToast(SIGNUP_PHONE_WE_SENT_YOU_NEW_ACTIVATION_CODE_SMS);
            }
            yield put(loginAPI.success(
                loginFd,
                response.body.user,
                response.body.initialState,
                normalizedResponse,
            ));
        }
    } catch (error) {
        if (error.response?.body?.phoneNumber === null || !!error.response?.body?.errors?.rawPhoneNumber) {
            yield put(mkReduxFormOpenPage(LOGIN_FORM_NAME, FormPageType.LoginSupport));
        }
        yield handleXHRFormErrorSaga(LOGIN_FORM_NAME, error as XHRFormError, logger);
        yield put(loginAPI.failure(loginFd, normalizeError(error)));
    }
}

export default function* root() {
    yield all([
        takeLatest(LOGIN_FETCH_TRIGGER, fetch),
        takeLatest(LOGIN_TRIGGER, login),
    ]);
}
