import { CANCEL, CANCEL_CHANGES, CONFIRM, CONFIRM_PHOTOS } from 'mk/autogenerated/translations/FormPage.492fee47fb29a3b9f21b8716f4ade9d3'
import { Btn, BtnType } from 'mk2/components/Btn';
import { FormPageType } from 'mk2/constants/enums';
import Page from 'mk2/pages/Page';
import React from 'react';

export interface FormPageStateProps {
    currentFormPage: FormPageType;
    forceMainHeaderFooter?: boolean;
    forceHideFooter?: boolean;
    isMobile: boolean;
}

export interface FormPageDispatchProps {
    onOpenFormPage(formPage: FormPageType);
    onCloseFormPage();
    onCancelFormPage();
}

export default abstract class FormPage<
        TOwnProps,
        TStateProps extends FormPageStateProps,
        TDispatchProps extends FormPageDispatchProps,
        TMergedProps = TOwnProps & TStateProps & TDispatchProps
    >
    extends Page<TOwnProps, TStateProps, TDispatchProps> {

    public hasHeaderBackButton(props/*: TMergedProps */): boolean {
        return true;
    }

    public getHeaderLeft(props/*: TMergedProps */): JSX.Element {
        const { currentFormPage, onCancelFormPage, forceMainHeaderFooter } = props;

        const onCancelFormPageWithQuestion = () => {
            onCancelFormPage();
        };

        return currentFormPage === FormPageType.Main ||
            currentFormPage === FormPageType.SignupPhotoUpload ||
            currentFormPage === FormPageType.SignupPhoneVerification ||
            currentFormPage === FormPageType.SignupTokenValidation ||
            currentFormPage === FormPageType.PhoneVerificationTokenValidation ||
            currentFormPage === FormPageType.LoginPhoneVerification ||
            currentFormPage === FormPageType.LoginPhoneVerificationSupport ||
            currentFormPage === FormPageType.LoginSupport ||
            forceMainHeaderFooter
            ? super.getHeaderLeft(props)
            : (
                <Btn
                    type={BtnType.BlueTextLight}
                    label={currentFormPage === FormPageType.Photos ? CANCEL_CHANGES : CANCEL}
                    onClick={onCancelFormPageWithQuestion}
                />
            );
    }

    public getHeaderRight(props/*: TMergedProps */): React.ReactElement {
        const { currentFormPage, forceMainHeaderFooter } = props;

        const additionalEl = this.getHeaderAdditionalButtons(props);
        const submitEl = this.getSubmitButton(props);

        const mainFormPageTypeHeaderRight = (!!additionalEl || !!submitEl)
            ? (
                <>
                    {additionalEl}
                    {!!additionalEl && !!submitEl && '\u00A0\u00A0'}
                    {submitEl}
                </>
            ) : null;

        if (forceMainHeaderFooter) {
            return mainFormPageTypeHeaderRight;
        }

        switch (currentFormPage) {
            case FormPageType.Main:
                return mainFormPageTypeHeaderRight;
            case FormPageType.AnonymousModeExplanation:
            case FormPageType.SelectTopicCategory:
                return null;
            default:
                return this.getConfirmButton(props);
        }
    }

    public getHeaderAdditionalButtons(props: TMergedProps): React.ReactElement {
        return null;
    }

    public getFooter(props/*: TMergedProps */): React.ReactNode {
        const { currentFormPage, isMobile, forceMainHeaderFooter, forceHideFooter } = props;

        const mainFormPageTypeFooter = !isMobile && this.getSubmitButton(props);

        if (forceMainHeaderFooter) {
            return mainFormPageTypeFooter;
        }

        if (forceHideFooter) {
            return null;
        }

        switch (currentFormPage) {
            case FormPageType.Main:
                return mainFormPageTypeFooter;
            case FormPageType.AnonymousModeExplanation:
            case FormPageType.SelectTopicCategory:
            case FormPageType.BazaarCheckoutLocation:

                return null;
            default:
                return !isMobile && this.getConfirmButton(props);
        }
    }

    /**
     * Get submit button for header
     */
    public abstract getSubmitButton(props: TMergedProps): React.ReactElement;

    /**
     * Allow children to override confirm button
     */
    public getConfirmButton(props/*: TMergedProps */): React.ReactElement {
        const { onCloseFormPage, currentFormPage } = props;

        return (
            <Btn
                label={currentFormPage === FormPageType.Photos ? CONFIRM_PHOTOS : CONFIRM}
                onClick={onCloseFormPage}
                data-cy="confirm-button"
            />
        );
    }

}
