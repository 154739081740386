import { SignupFormData } from 'mk2/apps/users/containers/Signup/SignupForm';
import { QueryParams } from 'mk2/apps/users/containers/Signup/SignupPage';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { AppStateProvidedFromServer } from 'mk2/reducers';
import { UserEntity } from 'mk2/schemas';

///////////////////////////////////////////////////////////////////
// Signup form

export const SIGNUP_TRIGGER = 'SIGNUP_TRIGGER';
export interface SignupTriggerAction {
    readonly type: typeof SIGNUP_TRIGGER;
    readonly signupFd: SignupFormData;
    readonly query: QueryParams;
}
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export interface SignupRequestAction extends APIRequestAction {
    readonly type: typeof SIGNUP_REQUEST;
    readonly signupFd: SignupFormData;
    readonly query: QueryParams;
}
export interface SignupNormalizedResponse extends NormalizedResponse {
    readonly result: { user: number; };
}
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export interface SignupSuccessAction extends APISuccessAction<SignupNormalizedResponse> {
    readonly type: typeof SIGNUP_SUCCESS;
    readonly signupFd: SignupFormData;
    readonly user: UserEntity;
    readonly initialState: AppStateProvidedFromServer;
}
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export interface SignupFailureAction extends APIFailureAction {
    readonly type: typeof SIGNUP_FAILURE;
    readonly signupFd: SignupFormData;
}

export const signupTrigger =  (signupFd: SignupFormData, query: QueryParams): SignupTriggerAction => ({
    type: SIGNUP_TRIGGER, signupFd, query,
});
export const signupAPI = {
    request: (signupFd: SignupFormData, query: QueryParams): SignupRequestAction => ({
        type: SIGNUP_REQUEST, signupFd, query,
    }),
    success: (signupFd: SignupFormData, user: UserEntity, initialState: AppStateProvidedFromServer, response: SignupNormalizedResponse): SignupSuccessAction => ({
        type: SIGNUP_SUCCESS, signupFd, user, initialState, response,
    }),
    failure: (signupFd: SignupFormData, error: any): SignupFailureAction => ({
        type: SIGNUP_FAILURE, signupFd, error,
    }),
};

///////////////////////////////////////////////////////////////////
// Validation

export const SIGNUP_VALIDATE_REGISTRATION_INPUTS_TRIGGER = 'SIGNUP_VALIDATE_REGISTRATION_INPUTS_TRIGGER';
export interface SignupValidateRegistrationInputsTriggerAction {
    readonly type: typeof SIGNUP_VALIDATE_REGISTRATION_INPUTS_TRIGGER;
    readonly signupFd: SignupFormData;
    readonly query: QueryParams;
}

export const SIGNUP_VALIDATE_REGISTRATION_INPUTS_REQUEST = 'SIGNUP_VALIDATE_REGISTRATION_INPUTS_REQUEST';
export interface SignupValidateRegistrationInputsRequestAction extends APIRequestAction {
    readonly type: typeof SIGNUP_VALIDATE_REGISTRATION_INPUTS_REQUEST;
    readonly signupFd: SignupFormData;
    readonly query: QueryParams;
}

export const SIGNUP_VALIDATE_REGISTRATION_INPUTS_SUCCESS = 'SIGNUP_VALIDATE_REGISTRATION_INPUTS_SUCCESS';
export interface SignupValidateRegistrationInputsNormalizedResponse extends NormalizedResponse {
    readonly result: {};
}
export interface SignupValidateRegistrationInputsSuccessAction extends APISuccessAction<SignupValidateRegistrationInputsNormalizedResponse> {
    readonly type: typeof SIGNUP_VALIDATE_REGISTRATION_INPUTS_SUCCESS;
    readonly signupFd: SignupFormData;
    readonly query: QueryParams;
}

export const SIGNUP_VALIDATE_REGISTRATION_INPUTS_FAILURE = 'SIGNUP_VALIDATE_REGISTRATION_INPUTS_FAILURE';
export interface SignupValidateRegistrationInputsFailureAction extends APIFailureAction {
    readonly type: typeof SIGNUP_VALIDATE_REGISTRATION_INPUTS_FAILURE;
    readonly signupFd: SignupFormData;
    readonly query: QueryParams;
}

export const signupValidateRegistrationInputsTrigger =  (signupFd: SignupFormData, query: QueryParams): SignupValidateRegistrationInputsTriggerAction => ({
    type: SIGNUP_VALIDATE_REGISTRATION_INPUTS_TRIGGER, signupFd, query,
});

export const signupValidateRegistrationInputsApi = {
    request: (signupFd: SignupFormData, query: QueryParams): SignupValidateRegistrationInputsRequestAction => ({
        type: SIGNUP_VALIDATE_REGISTRATION_INPUTS_REQUEST,
        signupFd, query,
    }),
    success: (signupFd: SignupFormData, query: QueryParams, response: SignupValidateRegistrationInputsNormalizedResponse): SignupValidateRegistrationInputsSuccessAction => ({
        type: SIGNUP_VALIDATE_REGISTRATION_INPUTS_SUCCESS,
        signupFd, query, response,
    }),
    failure: (signupFd: SignupFormData, query: QueryParams, error: any): SignupValidateRegistrationInputsFailureAction => ({
        type: SIGNUP_VALIDATE_REGISTRATION_INPUTS_FAILURE,
        signupFd, query, error,
    }),
};

///////////////////////////////////////////////////////////////////
// Phone request token

export const SIGNUP_PHONE_REQUEST_TOKEN_TRIGGER = 'SIGNUP_PHONE_REQUEST_TOKEN_TRIGGER';
export interface SignupPhoneRequestTokenTriggerAction {
    readonly type: typeof SIGNUP_PHONE_REQUEST_TOKEN_TRIGGER;
    readonly countryPrefix: string;
    readonly rawPhoneNumber: string;
    readonly resend: boolean;
}

export const SIGNUP_PHONE_REQUEST_TOKEN_REQUEST = 'SIGNUP_PHONE_REQUEST_TOKEN_REQUEST';
export interface SignupPhoneRequestTokenRequestAction extends APIRequestAction {
    readonly type: typeof SIGNUP_PHONE_REQUEST_TOKEN_REQUEST;
    readonly countryPrefix: string;
    readonly rawPhoneNumber: string;
}

export const SIGNUP_PHONE_REQUEST_TOKEN_SUCCESS = 'SIGNUP_PHONE_REQUEST_TOKEN_SUCCESS';
export interface SignupPhoneRequestTokenNormalizedResponse extends NormalizedResponse {
    readonly result: { fullPhoneNumber: string; };
}
export interface SignupPhoneRequestTokenSuccessAction extends APISuccessAction<SignupPhoneRequestTokenNormalizedResponse> {
    readonly type: typeof SIGNUP_PHONE_REQUEST_TOKEN_SUCCESS;
    readonly countryPrefix: string;
    readonly rawPhoneNumber: string;
}

export const SIGNUP_PHONE_REQUEST_TOKEN_FAILURE = 'SIGNUP_PHONE_REQUEST_TOKEN_FAILURE';
export interface SignupPhoneRequestTokenFailureAction extends APIFailureAction {
    readonly type: typeof SIGNUP_PHONE_REQUEST_TOKEN_FAILURE;
    readonly countryPrefix: string;
    readonly rawPhoneNumber: string;
}

export const signupPhoneRequestTokenTrigger = (countryPrefix: string, rawPhoneNumber: string, resend: boolean): SignupPhoneRequestTokenTriggerAction => ({
    type: SIGNUP_PHONE_REQUEST_TOKEN_TRIGGER,
    countryPrefix, rawPhoneNumber, resend,
});

export const signupPhoneRequestTokenApi = {
    request: (countryPrefix: string, rawPhoneNumber: string): SignupPhoneRequestTokenRequestAction => ({
        type: SIGNUP_PHONE_REQUEST_TOKEN_REQUEST,
        countryPrefix, rawPhoneNumber,
    }),
    success: (countryPrefix: string, rawPhoneNumber: string, response: SignupPhoneRequestTokenNormalizedResponse): SignupPhoneRequestTokenSuccessAction => ({
        type: SIGNUP_PHONE_REQUEST_TOKEN_SUCCESS,
        countryPrefix, rawPhoneNumber, response,
    }),
    failure: (countryPrefix: string, rawPhoneNumber: string, error: any): SignupPhoneRequestTokenFailureAction => ({
        type: SIGNUP_PHONE_REQUEST_TOKEN_FAILURE,
        countryPrefix, rawPhoneNumber, error,
    }),
};


///////////////////////////////////////////////////////////////////
// Phone validate token

export const SIGNUP_PHONE_VALIDATE_TOKEN_TRIGGER = 'SIGNUP_PHONE_VALIDATE_TOKEN_TRIGGER';
export interface SignupPhoneValidateTokenTriggerAction {
    readonly type: typeof SIGNUP_PHONE_VALIDATE_TOKEN_TRIGGER;
    readonly token: string;
    readonly fullPhoneNumber: string;
}

export const SIGNUP_PHONE_VALIDATE_TOKEN_REQUEST = 'SIGNUP_PHONE_VALIDATE_TOKEN_REQUEST';
export interface SignupPhoneValidateTokenRequestAction extends APIRequestAction {
    readonly type: typeof SIGNUP_PHONE_VALIDATE_TOKEN_REQUEST;
    readonly token: string;
    readonly fullPhoneNumber: string;
}

export const SIGNUP_PHONE_VALIDATE_TOKEN_SUCCESS = 'SIGNUP_PHONE_VALIDATE_TOKEN_SUCCESS';
export interface SignupPhoneValidateTokenNormalizedResponse extends NormalizedResponse {
    readonly result: {};
}
export interface SignupPhoneValidateTokenSuccessAction extends APISuccessAction<SignupPhoneValidateTokenNormalizedResponse> {
    readonly type: typeof SIGNUP_PHONE_VALIDATE_TOKEN_SUCCESS;
    readonly token: string;
    readonly fullPhoneNumber: string;
}

export const SIGNUP_PHONE_VALIDATE_TOKEN_FAILURE = 'SIGNUP_PHONE_VALIDATE_TOKEN_FAILURE';
export interface SignupPhoneValidateTokenFailureAction extends APIFailureAction {
    readonly type: typeof SIGNUP_PHONE_VALIDATE_TOKEN_FAILURE;
    readonly token: string;
    readonly fullPhoneNumber: string;
}

export const signupPhoneValidateTokenTrigger = (token: string, fullPhoneNumber: string): SignupPhoneValidateTokenTriggerAction => ({
    type: SIGNUP_PHONE_VALIDATE_TOKEN_TRIGGER,
    token, fullPhoneNumber,
});

export const signupPhoneValidateTokenApi = {
    request: (token: string, fullPhoneNumber: string): SignupPhoneValidateTokenRequestAction => ({
        type: SIGNUP_PHONE_VALIDATE_TOKEN_REQUEST,
        token, fullPhoneNumber,
    }),
    success: (token: string, fullPhoneNumber: string, response: SignupPhoneValidateTokenNormalizedResponse): SignupPhoneValidateTokenSuccessAction => ({
        type: SIGNUP_PHONE_VALIDATE_TOKEN_SUCCESS,
        token, fullPhoneNumber, response,
    }),
    failure: (token: string, fullPhoneNumber: string, error: any): SignupPhoneValidateTokenFailureAction => ({
        type: SIGNUP_PHONE_VALIDATE_TOKEN_FAILURE,
        token, fullPhoneNumber, error,
    }),
};

///////////////////////////////////////////////////////////////////
// Signup Load

export const SIGNUP_LOAD_TRIGGER = 'SIGNUP_LOAD_TRIGGER';
export interface SignupLoadAction {
    readonly contentItemsKey: string;
}

export interface SignupLoadTriggerAction extends SignupLoadAction {
    readonly type: typeof SIGNUP_LOAD_TRIGGER;
}
export const SIGNUP_LOAD_API_REQUEST = 'SIGNUP_LOAD_API_REQUEST';
export interface SignupLoadApiRequestAction extends APIRequestAction, SignupLoadAction {
    readonly type: typeof SIGNUP_LOAD_API_REQUEST;
}

export const SIGNUP_LOAD_API_SUCCESS = 'SIGNUP_LOAD_API_SUCCESS';
export interface SignupLoadNormalizedResponse extends NormalizedResponse {
    readonly result: {
        contentItemTop: number;
        contentItemBottom: number;
    };
}
export interface SignupLoadApiSuccessAction extends APISuccessAction<SignupLoadNormalizedResponse>, SignupLoadAction  {
    readonly type: typeof SIGNUP_LOAD_API_SUCCESS;
}
export const SIGNUP_LOAD_API_FAILURE = 'SIGNUP_LOAD_FAILURE';
export interface SignupLoadApiFailureAction extends APIFailureAction, SignupLoadAction  {
    readonly type: typeof SIGNUP_LOAD_API_FAILURE;
}

export const signupLoadTrigger = (contentItemsKey: string): SignupLoadTriggerAction => ({
        type: SIGNUP_LOAD_TRIGGER, contentItemsKey,
    });
export const signupLoadApi = {
    request: (contentItemsKey: string): SignupLoadApiRequestAction => ({
        type: SIGNUP_LOAD_API_REQUEST, contentItemsKey,
    }),
    success: (contentItemsKey: string, response: any): SignupLoadApiSuccessAction => ({
        type: SIGNUP_LOAD_API_SUCCESS, contentItemsKey, response,
    }),
    failure: (contentItemsKey: string, error: any): SignupLoadApiFailureAction => ({
        type: SIGNUP_LOAD_API_FAILURE, contentItemsKey, error,
    }),
};
