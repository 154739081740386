// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const LOGIN_BACK_TO_VERIFICATION = "< Zp\u011bt na zad\u00e1n\u00ed SMS k\u00f3du";
export const LOGIN_BUTTON = "P\u0159ihlas m\u011b";
export const LOGIN_CALL_SUPPORT = "Zavolej na podporu Modr\u00e9ho Kon\u00edka:";
export const LOGIN_CHANGE_PHONE_NUMBER = "Zm\u011bna telefonn\u00edho \u010d\u00edsla";
export const LOGIN_CHANGE_PHONE_NUMBER_INFO = "Pro zm\u011bnu telefonn\u00edho \u010d\u00edsla je pot\u0159eba, aby t\u011b manu\u00e1ln\u011b ov\u011b\u0159ily na\u0161e administr\u00e1torky.";
export const LOGIN_I_FORGOT_PASSWORD = "Zapomn\u011bla jsem heslo.";
export const LOGIN_NO_PHONE_VERIFICATION = "Do sv\u00e9ho \u00fa\u010dtu jsi se del\u0161\u00ed dobu nep\u0159ihl\u00e1sila. Sou\u010dasn\u011b ve sv\u00e9m \u00fa\u010dtu nem\u00e1\u0161 p\u0159idan\u00e9 telefonn\u00ed \u010d\u00edslo pro ov\u011b\u0159en\u00ed.";
export const LOGIN_NOT_REGISTERED_YET_THEN_url = "Je\u0161t\u011b nem\u00e1\u0161 login a heslo? %(url)s";
export const LOGIN_OTHER_PHONE_NUMBER = "M\u00e1m jin\u00e9 telefonn\u00ed \u010d\u00edslo";
export const LOGIN_PASSWORD = "Heslo:";
export const LOGIN_PHONE_VERIFICATION = "Ov\u011b\u0159en\u00ed telefonn\u00edho \u010d\u00edsla";
export const LOGIN_PHONE_VERIFICATION_CONTACTING_YOU_ON_number = "K\u00f3d ti p\u0159ijde na \u010d\u00edslo %(number)s";
export const LOGIN_PHONE_VERIFICATION_INFO = "Kv\u016fli ochran\u011b tv\u00e9ho \u00fa\u010dtu p\u0159ed neopr\u00e1vn\u011bn\u00fdm p\u0159ihl\u00e1\u0161en\u00edm je pot\u0159eba manu\u00e1ln\u00ed ov\u011b\u0159en\u00ed, p\u0159i kter\u00e9m ti k \u00fa\u010dtu p\u0159id\u00e1me telefonn\u00ed \u010d\u00edslo.";
export const LOGIN_REGISTER_HERE = "Zaregistruj se zde zdarma!";
export const LOGIN_SMS_VERIFICATION_INFO = "Do sv\u00e9ho \u00fa\u010dtu jsi se del\u0161\u00ed dobu nep\u0159ihl\u00e1sila. Kv\u016fli ochran\u011b tv\u00e9ho \u00fa\u010dtu p\u0159ed neopr\u00e1vn\u011bn\u00fdm p\u0159ihl\u00e1\u0161en\u00edm pot\u0159ebujeme, abys ov\u011b\u0159ila p\u0159ihl\u00e1\u0161en\u00ed pomoc\u00ed SMS k\u00f3du.";
export const LOGIN_SUPPORT_NUMBER = "+421 948 333 191";
export const LOGIN_USERNAME_OR_EMAIL = "P\u0159ihla\u0161ovac\u00ed jm\u00e9no nebo email:";
export const LOGIN_YOU_DID_NOT_ENTER_PASSWORD = "Nezadala jsi heslo.";
export const LOGIN_YOU_DID_NOT_ENTER_USERNAME = "Nezadala jsi p\u0159ihla\u0161ovac\u00ed jm\u00e9no nebo email.";
export const PHONE_VERIFICATION_TOKEN_CALL_LABEL = "Ov\u011b\u0159ovac\u00ed k\u00f3d z telefon\u00e1tu:";
export const PHONE_VERIFICATION_TOKEN_NOT_RECEIVED_RESEND_CALL = "K\u00f3d nem\u00e1m, zavolej mi znovu.";
export const PHONE_VERIFICATION_TOKEN_NOT_RECEIVED_RESEND_SMS = "K\u00f3d nep\u0159i\u0161el, po\u0161lete mi nov\u00fd.";
export const PHONE_VERIFICATION_TOKEN_SMS_LABEL = "Ov\u011b\u0159ovac\u00ed k\u00f3d z SMS:";
export const PHONE_VERIFICATION_WE_SENT_YOU_TOKEN_SMS = "Poslali jsme ti nov\u00fd ov\u011b\u0159ovac\u00ed k\u00f3d.";
export const SIGNUP_PHONE_VALIDATE_TOKEN = "Ode\u0161li";
