import {
    LoginFetchFailureAction,
    LoginFetchRequestAction,
    LoginFetchSuccessAction,
    LOGIN_FETCH_FAILURE,
    LOGIN_FETCH_REQUEST,
    LOGIN_FETCH_SUCCESS,
} from 'mk2/apps/users/containers/Login/Login.actions';
import { tupdate } from 'mk2/helpers/types';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { Reducer } from 'redux';

export interface LoginState {
    contentItemTopId: number;
    contentItemBottomId: number;
    loadingState: LoadingState;
}

export const initialLoginState: LoginState = {
    contentItemTopId: null,
    contentItemBottomId: null,
    loadingState: LoadingState.INIT,
};

export const initialLoginStateSentinel: LoginState = {
    contentItemTopId: null,
    contentItemBottomId: null,
    loadingState: LoadingState.SUCCESS,
};

export interface LoginPagesState {
    [contentItemsKey: string]: LoginState;
}

const initialLoginPagesState: LoginPagesState = {};

declare type LoginReducerAction =
    LoginFetchRequestAction
    | LoginFetchSuccessAction
    | LoginFetchFailureAction;

export const loginsReducer: Reducer<LoginPagesState> = (state = initialLoginPagesState, action: LoginReducerAction) => {
    switch (action.type) {
        case LOGIN_FETCH_REQUEST:
        case LOGIN_FETCH_FAILURE: {
            const contentItemsKey = action.contentItemsKey;
            return tupdate(state, {
                [contentItemsKey]: tupdate(state[contentItemsKey] || initialLoginState, {
                    loadingState: loadingState(undefined, action),
                }),
            });
        }

        case LOGIN_FETCH_SUCCESS: {
            const contentItemsKey = action.contentItemsKey;
            return tupdate(state, {
                [contentItemsKey]: tupdate(state[contentItemsKey] || initialLoginState, {
                    contentItemTopId: action.response.result.contentItemTop,
                    contentItemBottomId: action.response.result.contentItemBottom,
                    loadingState: loadingState(undefined, action),
                }),
            });
        }

        default:
            return state;
    }
};
