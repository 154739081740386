import {
    LOGIN_BACK_TO_VERIFICATION,
    LOGIN_BUTTON, LOGIN_CALL_SUPPORT, LOGIN_CHANGE_PHONE_NUMBER, LOGIN_CHANGE_PHONE_NUMBER_INFO,
    LOGIN_I_FORGOT_PASSWORD, LOGIN_NO_PHONE_VERIFICATION,
    LOGIN_NOT_REGISTERED_YET_THEN_url, LOGIN_OTHER_PHONE_NUMBER,
    LOGIN_PASSWORD, LOGIN_PHONE_VERIFICATION, LOGIN_PHONE_VERIFICATION_CONTACTING_YOU_ON_number,
    LOGIN_PHONE_VERIFICATION_INFO, LOGIN_REGISTER_HERE, LOGIN_SMS_VERIFICATION_INFO,
    LOGIN_SUPPORT_NUMBER,
    LOGIN_USERNAME_OR_EMAIL,
    LOGIN_YOU_DID_NOT_ENTER_PASSWORD,
    LOGIN_YOU_DID_NOT_ENTER_USERNAME,
    PHONE_VERIFICATION_TOKEN_CALL_LABEL,
    PHONE_VERIFICATION_TOKEN_NOT_RECEIVED_RESEND_CALL,
    PHONE_VERIFICATION_TOKEN_NOT_RECEIVED_RESEND_SMS,
    PHONE_VERIFICATION_TOKEN_SMS_LABEL,
    PHONE_VERIFICATION_WE_SENT_YOU_TOKEN_SMS,
    SIGNUP_PHONE_VALIDATE_TOKEN,
} from 'mk/autogenerated/translations/LoginForm.992ea0917504cc479bc65b734eb6b87d'
import { QueryParams } from 'mk2/apps/users/containers/Login/LoginPage';
import { SIGNUP_PHONE_VALIDATE_TOKEN_TRIGGER } from 'mk2/apps/users/containers/Signup/Signup.actions';
import { signupUrl, usersPasswordResetUrl } from 'mk2/apps/users/urls';
import { gtmEvent, hasValidationViaSms, prettyNumber } from 'mk2/apps/users/utils';
import { InputField } from 'mk2/components/forms/InputField';
import { PasswordField } from 'mk2/components/forms/PasswordField';
import { SubmitButton } from 'mk2/components/forms/SubmitButton';
import { Btn, BtnSidePadding, BtnType } from 'mk2/components/Btn';
import { Form } from 'mk2/components/Form';
import { Interpolate } from 'mk2/components/Interpolate';
import { Link } from 'mk2/components/Link';
import { FormPageType } from 'mk2/constants/enums';
import ActionButton from 'mk2/containers/ActionButton/ActionButton';
import { mkReduxForm, MKReduxFormInjectedProps } from 'mk2/decorators/mkReduxForm';
import { url, GetParams } from 'mk2/helpers/urls';
import React from 'react';
import { FormErrors } from 'redux-form';
import styles from './LoginForm.mscss';

export const LOGIN_FORM_NAME = 'LOGIN_FORM';

interface OwnProps {
    initialValues: LoginFormData;
    currentValues: LoginFormData;
    query: QueryParams;
    onLogin(values: LoginFormData, action: string, resend?: boolean);
    onStoreEvent?(name: string, props: any);
}

interface OwnState {
    passwordAutofillTriggered: boolean;
    usernameAutofillTriggered: boolean;
}

export interface LoginFormData {
    username: string;
    password: string;
    token: string;
    lastLoginVerified: boolean;
    phoneNumber: string;
    action: string;
}

type Props = OwnProps;

class LoginFormComponent extends React.Component<Props & MKReduxFormInjectedProps<LoginFormData, Props>, OwnState> {

    public static validate(values: LoginFormData): FormErrors<LoginFormData> {
        const errors: FormErrors<LoginFormData> = {};

        if (!values.username) {
            errors.username = LOGIN_YOU_DID_NOT_ENTER_USERNAME;
        }

        if (!values.password) {
            errors.password = LOGIN_YOU_DID_NOT_ENTER_PASSWORD;
        }

        return errors;
    }

    public state: OwnState = {
        passwordAutofillTriggered: false,
        usernameAutofillTriggered: false,
    };

    public componentDidMount() {
        document.addEventListener('keyup', this.handleOnKeyPressed);
    }

    public componentWillUnmount() {
        document.removeEventListener('keyup', this.handleOnKeyPressed);
        const { onStoreEvent, query } = this.props;
        gtmEvent('event_login_visited');
        if (onStoreEvent) {
            onStoreEvent('login_visited', {
                utm_campaign: query.utm_campaign ?? query.mkutm_campaign,
                utm_medium: query.utm_medium ?? query.mkutm_medium,
                utm_source: query.utm_source ?? query.mkutm_source,
                utm_content: query.utm_content ?? query.mkutm_content,
                page_referrer: (typeof window !== 'undefined') ? window.document.referrer : '',
            });
        }
    }

    public render() {
        const {currentFormPage} = this.props;
        if (currentFormPage === FormPageType.LoginSupport) {
            return this.renderContactSupport();
        } else if (currentFormPage === FormPageType.LoginPhoneVerificationSupport) {
            return this.renderPhoneVerificationSupport();
        } else if (currentFormPage === FormPageType.LoginPhoneVerification) {
            return this.renderPhoneVerification();
        } else if (currentFormPage === FormPageType.Main) {
            return this.renderMain();
        } else {
            throw new Error('Invalid FormPage');
        }
    }

    public renderMain() {  // step 1
        const { handleSubmit, query } = this.props;

        return (
            <div className={styles.LoginForm}>

                <Form onSubmit={handleSubmit}>
                    <InputField
                        name="username"
                        type="text"
                        label={LOGIN_USERNAME_OR_EMAIL}
                        autoCapitalize="off"
                        autoCorrect="off"
                        marginLeft={false}
                        onAutofill={this.handleUsernameAutofill}
                        minimizedLabel={this.state.usernameAutofillTriggered}
                    />
                    <PasswordField
                        name="password"
                        label={LOGIN_PASSWORD}
                        marginLeft={false}
                        onAutofill={this.handlePasswordAutofill}
                        minimizedLabel={this.state.passwordAutofillTriggered}
                    />

                    <SubmitButton
                       className={styles.LoginForm__button}
                       formName={LOGIN_FORM_NAME}
                       label={LOGIN_BUTTON}
                    />
                </Form>

                <p className={styles.LoginForm__forgot}>
                    <Link to={usersPasswordResetUrl}>{LOGIN_I_FORGOT_PASSWORD}</Link>
                </p>

                <p className={styles.LoginForm__register} >
                    <Interpolate
                        i18nKey={LOGIN_NOT_REGISTERED_YET_THEN_url}
                        url={(
                            <Link to={url(signupUrl, {}, query as unknown as GetParams)}>
                                {LOGIN_REGISTER_HERE}
                            </Link>
                        )}
                    />
                </p>
            </div>
        );
    }

    public renderPhoneVerification() {  // step 2
        const { currentValues } = this.props;
        const phoneNumber = currentValues.phoneNumber;

        return (
            <div className={styles.LoginForm}>
                <div>
                    <div className={styles.LoginForm__textWrapper}>
                        <h1 className={styles.LoginForm__title}>
                            {PHONE_VERIFICATION_WE_SENT_YOU_TOKEN_SMS}
                        </h1>
                        <p className={styles.LoginForm__text}>
                            {LOGIN_SMS_VERIFICATION_INFO}
                        </p>
                        <strong>
                        <Interpolate
                            className={styles.LoginForm__number}
                            i18nKey={LOGIN_PHONE_VERIFICATION_CONTACTING_YOU_ON_number}
                            number={(
                                <>
                                    {prettyNumber(phoneNumber)}
                                </>
                            )}
                        /></strong>
                        <p className={styles.LoginForm__text}>
                            <Btn
                                label={LOGIN_OTHER_PHONE_NUMBER}
                                sidePadding={BtnSidePadding.None}
                                type={BtnType.BlueTextLight}
                                onClick={this.handleChangeNumberClick}
                            />
                        </p>
                    </div>
                    <InputField
                        name="token"
                        type="text"
                        label={hasValidationViaSms(phoneNumber)
                            ? PHONE_VERIFICATION_TOKEN_SMS_LABEL
                            : PHONE_VERIFICATION_TOKEN_CALL_LABEL}
                    />
                    <div className={styles.LoginForm__phoneActions}>
                        <ActionButton
                            type={BtnType.Blue}
                            label={SIGNUP_PHONE_VALIDATE_TOKEN}
                            onClick={this.handleSubmit}
                            triggerAction={SIGNUP_PHONE_VALIDATE_TOKEN_TRIGGER}
                            showSpinnerWhenBusy
                        />
                        <Btn
                            onClick={this.handleResendToken}
                            label={hasValidationViaSms(phoneNumber)
                                ? PHONE_VERIFICATION_TOKEN_NOT_RECEIVED_RESEND_SMS
                                : PHONE_VERIFICATION_TOKEN_NOT_RECEIVED_RESEND_CALL
                            }
                            sidePadding={BtnSidePadding.None}
                            type={BtnType.BlueTextLight}
                            classNames={{
                                label: styles.LoginForm__secondaryBtn,
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    public renderPhoneVerificationSupport() {  // step 3
        return (
            <div className={styles.LoginForm}>
                <div>
                    <div className={styles.LoginForm__textWrapper}>
                        <h1 className={styles.LoginForm__title}>
                            {LOGIN_CHANGE_PHONE_NUMBER}
                        </h1>
                        <p className={styles.LoginForm__text}>
                            {LOGIN_CHANGE_PHONE_NUMBER_INFO}
                        </p>
                        <h3 className={styles.LoginForm__secondaryTitle}>
                            {LOGIN_CALL_SUPPORT}
                            <p className={styles.LoginForm__secondaryTitle__supportPhone}>
                                {LOGIN_SUPPORT_NUMBER}
                            </p>
                        </h3>
                        <p className={styles.LoginForm__text}>
                            <Btn
                                label={LOGIN_BACK_TO_VERIFICATION}
                                sidePadding={BtnSidePadding.None}
                                type={BtnType.BlueTextLight}
                                onClick={this.handleBackToTokenValidation}
                            />
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    public renderContactSupport() {  // case 2, step 1
        return (
            <div className={styles.LoginForm}>
                <div>
                    <div className={styles.LoginForm__textWrapper}>
                        <h1 className={styles.LoginForm__title}>
                            {LOGIN_PHONE_VERIFICATION}
                        </h1>
                        <p className={styles.LoginForm__text}>
                            {LOGIN_NO_PHONE_VERIFICATION}
                        </p>
                        <p className={styles.LoginForm__text}>
                            {LOGIN_PHONE_VERIFICATION_INFO}
                        </p>
                        <h3 className={styles.LoginForm__secondaryTitle}>
                            {LOGIN_CALL_SUPPORT}
                            <p className={styles.LoginForm__secondaryTitle__supportPhone}>
                                {LOGIN_SUPPORT_NUMBER}
                            </p>
                        </h3>
                    </div>
                </div>
            </div>
        );
    }

    private handlePasswordAutofill = () => {
        this.setState({passwordAutofillTriggered: true});
    };

    private handleUsernameAutofill = () => {
        this.setState({usernameAutofillTriggered: true});
    };

    private handleSubmit = () => {
        const { currentValues, onLogin, currentFormPage } = this.props;
        if (currentFormPage === FormPageType.Main) {
            onLogin(currentValues, 'login');
        } else if (currentFormPage === FormPageType.LoginPhoneVerification) {
            onLogin(currentValues, 'verify');
        }
    };

    private handleResendToken = () => {
        const { currentValues, onLogin} = this.props;
        onLogin(currentValues, 'login', true); // We call login again and login sends token again
    };

    private handleChangeNumberClick = () => {
        const { onOpenFormPage } = this.props;
        onOpenFormPage(FormPageType.LoginPhoneVerificationSupport);
    };

    private handleBackToTokenValidation = () => {
        const { onOpenFormPage } = this.props;
        onOpenFormPage(FormPageType.LoginPhoneVerification);
    };

    private handleOnKeyPressed = (e: KeyboardEvent) => {
        const target = e.target as HTMLElement;
        if (target.tagName === 'INPUT' && e.which === 13) {
            e.stopPropagation();
            e.preventDefault();
            this.handleSubmit();
        }
    };
}

export const LoginForm = mkReduxForm<LoginFormData, Props>({
    formName: LOGIN_FORM_NAME,
    onValidate: LoginFormComponent.validate,
    onSave: (values, props) => props.onLogin(values, 'login'),
    disableDirtyCheck: true,
})(LoginFormComponent);
