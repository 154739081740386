// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const HOMEPAGE_HEADING = "Poj\u010f na Modr\u00e9ho kon\u00edka!";
export const HOMEPAGE_PARA_1_CONTENT = "Modr\u00fd kon\u00edk nav\u0161t\u011bvuj\u00ed tis\u00edce \u017een z ka\u017ed\u00e9ho koutu \u010cesk\u00e9 republiky. Proto Ti tu rychle porad\u00edme prakticky s \u010d\u00edmkoliv ohledn\u011b t\u011bhotenstv\u00ed, zdrav\u00ed, d\u011bt\u00ed, rodiny a \u017eivota \u017eeny celkov\u011b. V\u017edy se najde p\u00e1r holek, kter\u00e9 \u0159e\u0161ily n\u011bco podobn\u00e9ho jako Ty :)";
export const HOMEPAGE_PARA_1_HEADING = "M\u00e1me spole\u010dn\u00e9 t\u00e9ma...";
export const HOMEPAGE_PARA_2_CONTENT = "Schv\u00e1ln\u011b, zkus se na n\u011bco zeptat po registraci a odpov\u011b\u010f od n\u011bkter\u00e9 z n\u00e1s uvid\u00ed\u0161 za p\u00e1r minut. Je moc fajn pocit, poradit se s ostatn\u00edmi holkami.";
export const HOMEPAGE_PARA_2_HEADING = "...a proto si um\u00edme poradit...";
export const HOMEPAGE_PARA_3_CONTENT = "Krom\u011b diskuze m\u00e1me na kon\u00edku i bezva d\u011btsk\u00fd bazar, kde je v\u00edce ne\u017e 470 000 inzer\u00e1t\u016f. Snadno n\u011bco p\u011bkn\u00e9ho koup\u00ed\u0161 nebo prod\u00e1\u0161.";
export const HOMEPAGE_PARA_3_HEADING = "...nebo pomoct obstarat v\u00fdbavi\u010dku";
export const HOME_SIGNUP_BAZAAR = "pravidly bazaru";
export const HOME_SIGNUP_I_AGREE_WITH_SITE_RULES_bazaar_link_common_link_privacy_link = "Registrac\u00ed souhlas\u00edm se %(common_link)s, %(bazaar_link)s a %(privacy_link)s.";
export const HOME_SIGNUP_PRIVACY = "Z\u00e1sadami ochrany osobn\u00edch \u00fadaj\u016f";
export const HOME_SIGNUP_VOP = "V\u0161eobecn\u00fdmi podm\u00ednkami pou\u017e\u00edv\u00e1n\u00ed";
