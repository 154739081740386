import cx from 'classnames';
import { LOGIN_TITLE, SIGNUP_TITLE } from 'mk/autogenerated/translations/SignupLoginTabs.8abd1b4a2abb13bcae19c1df974bd9a8'
import { QueryParams as LoginQueryParams } from 'mk2/apps/users/containers/Login/LoginPage';
import { QueryParams as SignupQueryParams } from 'mk2/apps/users/containers/Signup/SignupPage';
import { loginUrl, signupUrl } from 'mk2/apps/users/urls';
import { url, GetParams } from 'mk2/helpers/urls';
import React from 'react';
import { NavLink } from 'react-router-dom'; // tslint:disable-line
import styles from './SignupLoginTabs.mscss';

interface OwnProps {
    query: LoginQueryParams | SignupQueryParams;
}

type Props = OwnProps;

export class SignupLoginTabs extends React.Component<Props> {
    public render() {
        const { query } = this.props;

        const isLogin = (match, location) => {
            return location.pathname.startsWith(loginUrl);
        };

        const isSignup = (match, location) => {
            return location.pathname.startsWith(signupUrl);
        };

        return (
            <ul className={styles.SignupLoginTabs}>
                <li className={cx(styles.SignupLoginTabs__tab, styles['SignupLoginTabs__tab--first'])}>
                    <NavLink
                        className={styles.SignupLoginTabs__tablink}
                        activeClassName={styles['SignupLoginTabs__tablink--active']}
                        to={url(signupUrl, {}, query as unknown as GetParams)}
                        isActive={isSignup}
                    >
                        {SIGNUP_TITLE}
                    </NavLink>
                </li>
                <li className={styles.SignupLoginTabs__tab}>
                    <NavLink
                        className={styles.SignupLoginTabs__tablink}
                        activeClassName={styles['SignupLoginTabs__tablink--active']}
                        to={url(loginUrl, {}, query as unknown as GetParams)}
                        isActive={isLogin}
                    >
                        {LOGIN_TITLE}
                    </NavLink>
                </li>
            </ul>
        );
    }
}
