import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { FormControl } from 'mk2/components/forms/FormControl';
import { autoFocusElement, AutoFocus } from 'mk2/helpers/form';
import React from 'react';
import { EventWithDataHandler, Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';
import styles from './PasswordField.mscss';

export interface PasswordInputPublicProps {
    autoFocus?: AutoFocus;
    transparent?: boolean;
    // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#Preventing_autofilling_with_autocompletenew-password
    autoComplete?: 'new-password' | 'current-password';
}

type PasswordInputProps = PasswordInputPublicProps  & {
    input: WrappedFieldInputProps;

    onAutofill?();
};

interface PasswordInputState {
    showPassword: boolean;
}

class PasswordInput extends React.Component<PasswordInputProps, PasswordInputState> {

    private inputElem: HTMLInputElement;

    private autofillTriggered: () => void;

    constructor(props, context) {
        super(props, context);

        this.state = ({
            showPassword: false,
        });
        this.autofillTriggered = () => { this.labelUpdate(); };
    }

    public render() {
        const { input, autoFocus, transparent, autoComplete } = this.props;
        const { showPassword } = this.state;

        return (
            <div className={styles.PasswordInput}>
                <input
                    id={input.name}
                    className={styles.PasswordInput__input}
                    type={showPassword ? 'text' : 'password'}
                    autoFocus={!!autoFocus}
                    autoComplete={autoComplete}

                    {...input}
                    ref={this.handleInputRef}
                />

                <div className={cx(styles.PasswordInput__eyeContainer, transparent && styles['PasswordInput--transparent'])}>
                    <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        size="2x"
                        className={styles.PasswordInput__eyeContainer__icon}
                        onClick={this.handleOnClick}
                    />
                </div>
            </div>
        );
    }

    public componentDidMount() {
        autoFocusElement(this.inputElem, this.props.autoFocus, this.props.input.value);
        window.addEventListener('transitionend', this.autofillTriggered);
    }

    public componentWillUnmount() {
        window.removeEventListener('transitionend', this.autofillTriggered);
    }

    private handleInputRef = (ref: HTMLInputElement) => {
        this.inputElem = ref;
    };

    private handleOnClick = () => {
        this.setState({showPassword: !this.state.showPassword});
    };

    private labelUpdate() {
        if (this.props.onAutofill) {
            this.props.onAutofill();
        }
    }
}

interface PasswordControlPublicProps {
    label?: string;
    marginLeft?: boolean;
    minimizedLabel?: boolean;
    transparent?: boolean;

    onAutofill?();
}

type PasswordControlProps = PasswordInputPublicProps & PasswordControlPublicProps & WrappedFieldProps;

const PasswordControl: React.StatelessComponent<PasswordControlProps> = ({
     input, meta, label, marginLeft, transparent,
     minimizedLabel, onAutofill, ...pwInputProps
}) => (
    <FormControl
        marginLeft={marginLeft}
        transparent={transparent}
        input={input}
        label={label}
        meta={meta}
        minimizedLabel={minimizedLabel}
    >
        <PasswordInput
            transparent={transparent}
            input={input}
            onAutofill={onAutofill}
            {...pwInputProps}
        />
    </FormControl>
);

type PasswordFieldProps = PasswordInputPublicProps & PasswordControlPublicProps & {
    // see https://redux-form.com/7.3.0/docs/api/field.md/#props-you-can-pass-to-code-field-code-
    name: string;
    onChange?: EventWithDataHandler<React.ChangeEvent<any>>;
};

export class PasswordField extends React.Component<PasswordFieldProps> {
    public render() {
        return (
            <Field
                {...this.props}
                component={PasswordControl}
            />
        );
    }
}
