import { SignupLoginWrapper } from 'mk2/apps/users/components/SignupLoginWrapper';
import { LoginForm } from 'mk2/apps/users/containers/Login/LoginForm';
import { Props } from 'mk2/apps/users/containers/Login/LoginPage';
import { LoadingSwitch } from 'mk2/containers/LoadingSwitch/LoadingSwitch';
import { GetParams } from 'mk2/helpers/urls';
import React from 'react';
import styles from './Login.mscss';

export default class Login extends React.Component<Props> {

    public render() {
        const {
            currentFormPage, initialValues, isMobile, query, currentValues,
            contentItemsKey, contentItemTop, contentItemBottom, loadingState,
            onLogin, onStoreEvent,
         } = this.props;

        return (
            <SignupLoginWrapper
                className={styles.Login}
                query={query as unknown as GetParams}
                isMobile={isMobile}
                currentFormPage={currentFormPage}
                contentItemsKey={contentItemsKey}
                contentItemTop={contentItemTop}
                contentItemBottom={contentItemBottom}
            >
                <LoadingSwitch
                    loadingState={loadingState}
                    hasData={!contentItemsKey || !!contentItemTop}
                    onLoad={this.handleOnLoad}
                    onRenderInit={this.renderNothing}
                    onRenderSuccess={this.renderNothing}
                    onRenderFailure={this.renderNothing}
                />

                <LoginForm
                    initialValues={initialValues}
                    query={query}
                    onLogin={onLogin}
                    currentValues={currentValues}
                    onStoreEvent={onStoreEvent}
                />
            </SignupLoginWrapper>
        );
    }

    private renderNothing = () => null;

    private handleOnLoad = () => {
        const { contentItemsKey, onLoad } = this.props;
        if (contentItemsKey) {
            onLoad(contentItemsKey);
        }
    };
}
