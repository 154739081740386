import { AST } from 'mk2/apps/wiki/schemas';
import { astToHtml, defaultFilterAdditionalData, FilterAdditionalData } from 'mk2/helpers/article_ast/filters';
import React from 'react';

interface OwnProps {
    className?: string;
    contentAST: AST;
}

type Props = OwnProps & Partial<FilterAdditionalData>;

export const RenderAST: React.FunctionComponent<Props> = React.memo(({
    className,
    contentAST,
    ...rest
}) => {
    let renderedContent = null;
    if (contentAST) {
        const additionalData = {
            ...defaultFilterAdditionalData,
            ...rest,
        };
        renderedContent = astToHtml(contentAST, additionalData);
    }
    return (
        <>
            {renderedContent}
        </>
    );
});

RenderAST.displayName = 'RenderAST';
