import { SEO_TITLE_LOGIN } from 'mk/autogenerated/translations/LoginPage.17e967995c692adc9018cda08ff8d047'
import { analyticsStoreEvent } from 'mk2/actions';
import type { UtmQuery } from 'mk2/apps/home/schemas';
import Login from 'mk2/apps/users/containers/Login/Login';
import { loginFetchTrigger, loginTrigger } from 'mk2/apps/users/containers/Login/Login.actions';
import { loginsReducer } from 'mk2/apps/users/containers/Login/Login.reducers';
import loginSaga from 'mk2/apps/users/containers/Login/Login.sagas';
import { getLoginState } from 'mk2/apps/users/containers/Login/Login.selectors';
import { LoginFormData, LOGIN_FORM_NAME } from 'mk2/apps/users/containers/Login/LoginForm';
import { loginUrl } from 'mk2/apps/users/urls';
import { loginSignupTabs } from 'mk2/apps/users/utils';
import { AppName } from 'mk2/containers/Application/Application';
import { mkReduxFormMapDispatchToProps, mkReduxFormMapStateToProps } from 'mk2/decorators/mkReduxForm';
import { memoizeOne } from 'mk2/helpers/cache';
import { cachedInitialValues } from 'mk2/helpers/form';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { GetParams } from 'mk2/helpers/urls';
import FormPage, { FormPageDispatchProps, FormPageStateProps } from 'mk2/pages/FormPage';
import { PageLayout } from 'mk2/pages/Page';
import { getRequestDeviceMobile, getRequestLastKnownUsername, AppState } from 'mk2/reducers';
import { LoadingState } from 'mk2/reducers/loadingState';
import { ContentItemEntity, ContentItemSchema, HeaderMenuItem, MenuSelection } from 'mk2/schemas';
import { getDenormalizedEntity } from 'mk2/selectors';
import { Store } from 'mk2/store/configureStore';
import { parse } from 'query-string';
import React from 'react';
import { MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getFormValues } from 'redux-form';

export type QueryParams = UtmQuery & {
    next?: string;
    ci?: string;
    username?: string;
};

interface OwnProps {}

interface StateProps extends FormPageStateProps {
    initialValues: LoginFormData;
    currentValues: LoginFormData;
    isMobile: boolean;
    query: QueryParams;
    loadingState: LoadingState;
    contentItemsKey: string;
    contentItemBottom: ContentItemEntity;
    contentItemTop: ContentItemEntity;
}

interface DispatchProps extends FormPageDispatchProps {
    onLoad(contentItemsKey: string);
    onLogin(values: LoginFormData, action: string);
    onStoreEvent(name: string, props: any);
}

type RouteProps = RouteComponentProps;

export type Props = OwnProps & StateProps & DispatchProps & RouteProps;

export default class LoginPage extends FormPage<OwnProps & RouteProps, StateProps, DispatchProps> {

    private parseQuery = memoizeOne((qs: string): QueryParams => {
        // parse and sanitize input
        return parse(qs) as QueryParams;
    });

    public mapStateToProps(): MapStateToPropsParam<StateProps, OwnProps & RouteProps, AppState> {
        return (state: AppState, ownProps: OwnProps & RouteProps): StateProps => {
            const query = this.parseQuery(ownProps.location.search);
            const username = query.username
                ? (query.username as string).toLowerCase()
                : getRequestLastKnownUsername(state);

            const contentItemsKey = query.ci as string;
            const pageState = getLoginState(state, contentItemsKey);
            const contentItemTop = getDenormalizedEntity<ContentItemEntity>(state, ContentItemSchema, pageState.contentItemTopId);
            const contentItemBottom = getDenormalizedEntity<ContentItemEntity>(state, ContentItemSchema, pageState.contentItemBottomId);

            return {
                currentValues: getFormValues(LOGIN_FORM_NAME)(state) as LoginFormData,
                ...mkReduxFormMapStateToProps(LOGIN_FORM_NAME)(state),

                initialValues: cachedInitialValues(LOGIN_FORM_NAME, {
                    username: username || '',
                    password: '',
                    token: '',
                    lastLoginVerified: true,
                    phoneNumber: '',
                    action: 'login',
                }, username),
                isMobile: getRequestDeviceMobile(state),
                query,
                loadingState: pageState.loadingState,
                contentItemsKey,
                contentItemTop,
                contentItemBottom,
            };
        };
    }

    public mapDispatchToProps(): MapDispatchToPropsObject<DispatchProps> {
        return {
            ...mkReduxFormMapDispatchToProps(LOGIN_FORM_NAME),
            onLoad: loginFetchTrigger,
            onLogin: loginTrigger,
            onStoreEvent: analyticsStoreEvent,
        };
    }

    public getMetaTitle(props: Props): string {
        return SEO_TITLE_LOGIN;
    }

    public render(props: Props): false | JSX.Element {
        return (
            <Login {...props} />
        );
    }

    public hasPageHeaderPart1(props: Props): boolean {
        const { isMobile } = props;
        return isMobile;
    }

    public hasHeaderBackButton(props: Props): boolean {
        return false;
    }

    public getPageLayout(app: AppName, props: Props): PageLayout {
        return PageLayout.MainContent;
    }

    public getHeaderTabs(props: Props): (JSX.Element[] | null) {
        const { isMobile, query } = props;
        if (isMobile) {
            return loginSignupTabs('login', query as GetParams);
        }
    }

    public getHeaderMenuSelection(app: AppName, props: Props): MenuSelection {
        return {
            activeSection: app,
            activeItem: HeaderMenuItem.UserLogin,
        };
    }

    public hasMobileLogo(): boolean {
        return true;
    }

    public getSubmitButton(props: Props): React.ReactElement {
        return null;
    }

    public getFooter(props: Props): boolean {
        return null;
    }

    public getHeaderRight(props: Props): React.ReactElement {
        return null;
    }

    public getMetaRobots(props: Props): string {
        if (props.location.search !== '') {
            return 'noindex,nofollow';
        }

        return super.getMetaRobots(props);
    }

    public getCanonicalUrl(baseUrl: string, props: Props): string {
        return baseUrl + loginUrl;
    }

    public registerInStore(store: Store<AppState>) {
        store.injectReducer('containers.users.logins', loginsReducer);
        store.injectSaga('containers.users.logins', loginSaga);
    }
}
