import cx from 'classnames';
import {
    HOME_SIGNUP_BAZAAR,
    HOME_SIGNUP_I_AGREE_WITH_SITE_RULES_bazaar_link_common_link_privacy_link,
    HOME_SIGNUP_PRIVACY,
    HOME_SIGNUP_VOP,
    HOMEPAGE_HEADING,
    HOMEPAGE_PARA_1_CONTENT,
    HOMEPAGE_PARA_1_HEADING,
    HOMEPAGE_PARA_2_CONTENT,
    HOMEPAGE_PARA_2_HEADING,
    HOMEPAGE_PARA_3_CONTENT,
    HOMEPAGE_PARA_3_HEADING,
} from 'mk/autogenerated/translations/SignupLoginWrapper.0f963a8e53b62c70ca66a6befcf496d6'
import assetUrl from 'mk/common/assetUrl';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { aboutPrivacyPolicyUrl, aboutRulesCommonUrl, aboutRulesMarketUrl } from 'mk2/apps/about/urls';
import { homepageUrl } from 'mk2/apps/home/urls';
import { SignupLoginTabs } from 'mk2/apps/users/components/SignupLoginTabs';
import { QueryParams as LoginQueryParams } from 'mk2/apps/users/containers/Login/LoginPage';
import { QueryParams as SignupQueryParams } from 'mk2/apps/users/containers/Signup/SignupPage';
import { Img } from 'mk2/components/Img';
import { Interpolate } from 'mk2/components/Interpolate';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { RenderAST } from 'mk2/components/RenderAST';
import Sticker, { StickerSize } from 'mk2/components/Sticker';
import { FormPageType } from 'mk2/constants/enums';
import InstallPrompt from 'mk2/containers/InstallPrompt/InstallPrompt';
import { ShowPhotosAndVideos } from 'mk2/helpers/article_ast/filters';
import { GetParams } from 'mk2/helpers/urls';
import { ContentItemEntity } from 'mk2/schemas';
import React from 'react';
import styles from './SignupLoginWrapper.mscss';

interface OwnProps {
    className?: string;
    query: LoginQueryParams | SignupQueryParams;
    isMobile: boolean;
    currentFormPage: FormPageType;

    contentItemsKey?: string;
    contentItemBottom?: ContentItemEntity;
    contentItemTop?: ContentItemEntity;
}

type Props = OwnProps;

export class SignupLoginWrapper extends React.Component<Props> {

    public render() {
        const {
            query, children, className, isMobile, currentFormPage,
            contentItemsKey, contentItemTop, contentItemBottom,
        } = this.props;

        return (
            <div className={cx(styles.SignupLoginWrapper, className)}>
                <div className={styles.SignupLoginWrapper__wrapper}>

                    {isMobile && contentItemsKey &&
                        <div className={styles.SignupLoginWrapper__contentItemTop}>
                            {contentItemTop ? (
                                <RenderAST
                                    contentAST={contentItemTop.contentAST}
                                    linkedObjects={contentItemTop.contentASTAdditionalData.linkedObjects}
                                    showPhotosAndVideos={ShowPhotosAndVideos.ALL}
                                    renderSpecialWikiLinks={false}
                                    photos={contentItemTop.photos}
                                    allowSubstitutingGetParams={query as unknown as GetParams}
                                />
                            ) : ContentItemPlaceholder}
                        </div>
                    }

                    <div className={styles.SignupLoginWrapper__form}>
                        {!isMobile &&
                            <Link className={styles.SignupLoginWrapper__logo} to={homepageUrl}>
                                <Img src={assetUrl + 'img/logos/ver-3/logo-201@2x.png'} width={160} height={36} />
                            </Link>
                        }

                        {!isMobile && currentFormPage === FormPageType.Main &&
                            <SignupLoginTabs query={query}/>
                        }

                        {children /* the form */}

                        {!isMobile &&
                            <Sticker
                                className={styles.SignupLoginWrapper__sticker}
                                id={1}
                                size={StickerSize.XL}
                            />
                        }
                    </div>

                    {isMobile && contentItemsKey &&
                        <div className={styles.SignupLoginWrapper__contentItemBottom}>
                            {contentItemBottom ? (
                                <RenderAST
                                    contentAST={contentItemBottom.contentAST}
                                    linkedObjects={contentItemBottom.contentASTAdditionalData.linkedObjects}
                                    showPhotosAndVideos={ShowPhotosAndVideos.ALL}
                                    renderSpecialWikiLinks={false}
                                    photos={contentItemBottom.photos}
                                    allowSubstitutingGetParams={query as unknown as GetParams}
                                />
                            ) : ContentItemPlaceholder }
                        </div>
                    }

                    {!isMobile ? (
                        <div className={styles.SignupLoginWrapper__content_wrapper}>

                            {contentItemsKey && (
                                <div className={styles.SignupLoginWrapper__contentItemTop}>
                                    {contentItemTop ? (
                                        <RenderAST
                                            contentAST={contentItemTop.contentAST}
                                            linkedObjects={contentItemTop.contentASTAdditionalData.linkedObjects}
                                            showPhotosAndVideos={ShowPhotosAndVideos.ALL}
                                            renderSpecialWikiLinks={false}
                                            photos={contentItemTop.photos}
                                            allowSubstitutingGetParams={query as unknown as GetParams}
                                            customStyles={{
                                                header2: styles.SignupLoginWrapper__content__header,
                                            }}
                                        />
                                    ) : ContentItemPlaceholder}
                                </div>
                            )}

                            {!contentItemsKey && (
                                <>
                                    <div className={styles.SignupLoginWrapper__yt_video}>
                                        {ACTIVE_SERVER_ID === 202
                                            ? <iframe
                                                width="600"
                                                height="338"
                                                frameBorder="0"
                                                allowFullScreen
                                                src="https://www.youtube.com/embed/cKkBe3TOg0I?rel=0"
                                            />
                                            : <Img src={assetUrl + 'img/homepage/variant-b/main-2.png'} width={600} height={338}/>
                                        }
                                    </div>

                                    <div className={styles.SignupLoginWrapper__content}>
                                        <h1>{HOMEPAGE_HEADING}</h1>

                                        <div className={styles.SignupLoginWrapper__content_box}>
                                            <Img
                                                src={assetUrl + 'img/homepage/friends@2x.png'}
                                                width={80}
                                                height={80}
                                            />
                                            <div className={styles.SignupLoginWrapper__content_box__text}>
                                                <h2>{HOMEPAGE_PARA_1_HEADING}</h2>
                                                <p>{HOMEPAGE_PARA_1_CONTENT}</p>
                                            </div>
                                        </div>

                                        <div className={styles.SignupLoginWrapper__content_box}>
                                            <Img
                                                src={assetUrl + 'img/homepage/messaging@2x.png'}
                                                width={80}
                                                height={80}
                                            />
                                            <div className={styles.SignupLoginWrapper__content_box__text}>
                                                <h2>{HOMEPAGE_PARA_2_HEADING}</h2>
                                                <p>{HOMEPAGE_PARA_2_CONTENT}</p>
                                            </div>
                                        </div>

                                        <div className={styles.SignupLoginWrapper__content_box}>
                                            <Img
                                                src={assetUrl + 'img/homepage/stroller@2x.png'}
                                                width={80}
                                                height={80}
                                            />
                                            <div className={styles.SignupLoginWrapper__content_box__text}>
                                                <h2>{HOMEPAGE_PARA_3_HEADING}</h2>
                                                <p>{HOMEPAGE_PARA_3_CONTENT}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {contentItemsKey && (
                                <div className={styles.SignupLoginWrapper__contentItemBottom}>
                                    {contentItemBottom ? (
                                        <RenderAST
                                            contentAST={contentItemBottom.contentAST}
                                            linkedObjects={contentItemBottom.contentASTAdditionalData.linkedObjects}
                                            showPhotosAndVideos={ShowPhotosAndVideos.ALL}
                                            renderSpecialWikiLinks={false}
                                            photos={contentItemBottom.photos}
                                            allowSubstitutingGetParams={query as unknown as GetParams}
                                        />
                                    ) : ContentItemPlaceholder}
                                </div>
                            )}
                        </div>
                    ) : currentFormPage === FormPageType.Main && (
                        <>
                            <InstallPrompt/>
                            <div className={styles.SignupLoginWrapper__fixedToBottom}>
                                <Interpolate
                                    i18nKey={
                                        HOME_SIGNUP_I_AGREE_WITH_SITE_RULES_bazaar_link_common_link_privacy_link
                                    }
                                    bazaar_link={
                                        <Link
                                            to={aboutRulesMarketUrl}
                                            className={styles.SignupLoginWrapper__fixedToBottom__link}
                                        >
                                            {HOME_SIGNUP_BAZAAR}
                                        </Link>
                                    }
                                    privacy_link={
                                        <Link
                                            to={aboutPrivacyPolicyUrl}
                                            className={styles.SignupLoginWrapper__fixedToBottom__link}
                                        >
                                            {HOME_SIGNUP_PRIVACY}
                                        </Link>
                                    }
                                    common_link={
                                        <Link
                                            to={aboutRulesCommonUrl}
                                            className={styles.SignupLoginWrapper__fixedToBottom__link}
                                        >
                                            {HOME_SIGNUP_VOP}
                                        </Link>
                                    }
                                />
                            </div>
                            <div className={styles.SignupLoginWrapper__fixedToBottomPlaceholder} />
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const ContentItemPlaceholder = (
    <>
        <LoadingPlaceholder width={170} height={30} style={{marginTop: 20, marginBottom: 20}} />
        <LoadingPlaceholder width={'100%'} height={110} style={{marginBottom: 20}} />
    </>
);
