import { LoginFormData } from 'mk2/apps/users/containers/Login/LoginForm';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { AppStateProvidedFromServer } from 'mk2/reducers';
import { UserEntity } from 'mk2/schemas';

/////////////////////////////////////////////////////////////////////
// Login Load

export const LOGIN_FETCH_TRIGGER = 'LOGIN_FETCH_TRIGGER';
export interface LoginFetchTriggerAction {
    readonly type: typeof LOGIN_FETCH_TRIGGER;
    contentItemsKey: string;
}
export const LOGIN_FETCH_REQUEST = 'LOGIN_FETCH_REQUEST';
export interface LoginFetchRequestAction extends APIRequestAction {
    readonly type: typeof LOGIN_FETCH_REQUEST;
    contentItemsKey: string;
}
export interface LoginFetchNormalizedResponse extends NormalizedResponse {
    readonly result: {
        contentItemTop: number;
        contentItemBottom: number;
    };
}
export const LOGIN_FETCH_SUCCESS = 'LOGIN_FETCH_SUCCESS';
export interface LoginFetchSuccessAction extends APISuccessAction<LoginFetchNormalizedResponse> {
    readonly type: typeof LOGIN_FETCH_SUCCESS;
    contentItemsKey: string;
}
export const LOGIN_FETCH_FAILURE = 'LOGIN_FETCH_FAILURE';
export interface LoginFetchFailureAction extends APIFailureAction {
    readonly type: typeof LOGIN_FETCH_FAILURE;
    contentItemsKey: string;
}

export const loginFetchTrigger = (contentItemsKey: string): LoginFetchTriggerAction => ({
    type: LOGIN_FETCH_TRIGGER, contentItemsKey,
});
export const loginFetchApi = {
    request: (contentItemsKey: string): LoginFetchRequestAction => ({
        type: LOGIN_FETCH_REQUEST, contentItemsKey,
    }),
    success: (contentItemsKey: string, response: LoginFetchNormalizedResponse): LoginFetchSuccessAction => ({
        type: LOGIN_FETCH_SUCCESS, contentItemsKey, response,
    }),
    failure: (contentItemsKey: string, error: any): LoginFetchFailureAction => ({
        type: LOGIN_FETCH_FAILURE, contentItemsKey, error,
    }),
};

///////////////////////////////////////////////////////////////////
// Login submit form

export const LOGIN_TRIGGER = 'LOGIN_TRIGGER';
export interface LoginTriggerAction {
    readonly type: typeof LOGIN_TRIGGER;
    readonly action: string;
    readonly loginFd: LoginFormData;
    readonly resend: boolean;
}
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export interface LoginRequestAction extends APIRequestAction {
    readonly type: typeof LOGIN_REQUEST;
    readonly loginFd: LoginFormData;
}
export interface LoginNormalizedResponse extends NormalizedResponse {
    readonly result: { user: number; lastLoginVerified: boolean; phoneNumber: string; initialState: AppStateProvidedFromServer; };
}
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export interface LoginSuccessAction extends APISuccessAction<LoginNormalizedResponse> {
    readonly type: typeof LOGIN_SUCCESS;
    readonly loginFd: LoginFormData;
    readonly user: UserEntity;
    readonly initialState: AppStateProvidedFromServer;
}
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export interface LoginFailureAction extends APIFailureAction {
    readonly type: typeof LOGIN_FAILURE;
    readonly loginFd: LoginFormData;
}

export const loginTrigger =  (loginFd: LoginFormData, action: string, resend: boolean= false): LoginTriggerAction => ({
    type: LOGIN_TRIGGER, loginFd, action, resend,
});
export const loginAPI = {
    request: (loginFd: LoginFormData): LoginRequestAction => ({
        type: LOGIN_REQUEST, loginFd,
    }),
    success: (loginFd: LoginFormData, user: UserEntity, initialState: AppStateProvidedFromServer, response: LoginNormalizedResponse): LoginSuccessAction => ({
        type: LOGIN_SUCCESS, loginFd, user, initialState, response,
    }),
    failure: (loginFd: LoginFormData, error: any): LoginFailureAction => ({
        type: LOGIN_FAILURE, loginFd, error,
    }),
};
